import { logErrorGroup, printIsoDateTime } from "Utils";
import Rendezvous from "./ClassWrapper/Rendezvous";
import InfoModal from "./utility/InfoModal";
import Account from "./ClassWrapper/Account";
import { CANCEL_MOTIVE_FR } from "./ClassWrapper/CancelMotive";
import { useContext, useEffect, useState } from "react";
import UController from "./Controller/UController";
import { createContext } from "react";
import { createPortal } from "react-dom";

type Actors = {
    createdBy: Account | null,
    lastModifiedBy: Account | null,
    canceledBy: Account | null
};

/**
 * The modal to display the history of a rendez-vous
 */
const RdvHistoryModal = (props: {
    /**
     * The rendez-vous to display history
     */
    rdv: Rendezvous,
    /**
     * Callback when the modal is closed. Usually to reset the rendez-vous to display
     */
    onClosed: () => void
}) => {
    const [actors, setActors]: [Actors,] = useState(null);
    useEffect(() => {
        if (!props.rdv) return;
        Promise.all([
            getAccountByIdOrNull(props.rdv.createdBy, "createdBy"),
            getAccountByIdOrNull(props.rdv.lastModifiedBy, "lastModifiedBy"),
            getAccountByIdOrNull(props.rdv.realCancelBy, "realCancelBy")
        ]).then(([createdBy, lastModifiedBy, canceledBy]) => setActors({ createdBy, lastModifiedBy, canceledBy }))
    }, [props.rdv]);

    return (
        <InfoModal
            level="success"
            title="Historique du rendez-vous"
            msg={!actors ? "Chargement..." : <RdvHistoryContent rdv={props.rdv} {...actors} />}
            onClosed={props.onClosed}
        />
    )
}

const getAccountByIdOrNull = (accountId: String | null, actionTag: String): Promise<Account | null> => {
    if (!accountId) return Promise.resolve(null);
    const cached = ACCOUNT_CACHE.get(accountId);
    if (cached && cached.expiredAt > Date.now()) return Promise.resolve(cached.entity);
    return UController.crudAccount.get({
        page: 0,
        size: 1,
        search: `externalAuthId==${accountId}`
    })
        .then(page => page.array.length > 0 ? page.array[0] : null)
        .then(account => {
            ACCOUNT_CACHE.set(accountId, { entity: account, expiredAt: Date.now() + DEFAULT_TTL });
            return account;
        })
        .catch(error => {
            logErrorGroup("rdv_history." + actionTag, error);
            return null;
        });
}

const RdvHistoryContent = (props: {
    rdv: Rendezvous,
    createdBy: Account | null,
    lastModifiedBy: Account | null,
    canceledBy: Account | null
}) => (<ol style={{
    paddingLeft: "1rem",
    paddingBottom: "0",
    marginBottom: "0"
}}>
    <li>
        <span>Créé le {printIsoDateTime(props.rdv.createdDateTime, false)} par <AccountDisplay accountId={props.rdv.createdBy} account={props.createdBy} /></span>
    </li>
    {
        props.rdv.lastModifiedDateTime &&
        <li>
            <span>Modifié le {printIsoDateTime(props.rdv.lastModifiedDateTime, false)} par <AccountDisplay accountId={props.rdv.lastModifiedBy} account={props.lastModifiedBy} /></span>
        </li>
    }
    {
        props.rdv.realCancel &&
        <li>
            <span>
                Annulé le {printIsoDateTime(props.rdv.realCancel, false)} par <AccountDisplay accountId={props.rdv.realCancelBy} account={props.canceledBy} />{CANCEL_MOTIVE_FR[props.rdv.realCancelMotive] && ` pour le motive "${CANCEL_MOTIVE_FR[props.rdv.realCancelMotive]}"`}{props.rdv.realCancelComment && ` avec le commentaire "${props.rdv.realCancelComment}"`}
            </span>
        </li>
    }
</ol>)

const AccountDisplay = (props: { accountId: String | null, account: Account | null }) => (<b>{props.account?.username ? props.account.username : props.accountId ? props.accountId : "inconnu"}</b>)

type CacheEntity<M> = {
    entity: M,
    /**
     * Timestamp of expected expiration
     */
    expiredAt: number,
}
const DEFAULT_TTL = 1000 * 60 * 15; // 15 minutes
const ACCOUNT_CACHE: Map<String, CacheEntity<Account>> = new Map();

///////////////////////////
// Modal provider
// Inspired by https://dev.to/alexandprivate/your-next-react-modal-with-your-own-usemodal-hook-context-api-3jg7
///////////////////////////

const useRdvHistoryModal = () => {
    const [rdv, setRdv]: [Rendezvous, ((r: Rendezvous) => void)] = useState(null);
    const closeRdvModal = () => setRdv(null);
    return {
        rdv,
        setRdv,
        closeRdvModal
    }
}

const RdvHistoryModalContext = createContext(null);

const RdvHistoryModalPortal = ({
    rdv,
    closeRdvModal
}: {
    rdv: Rendezvous,
    closeRdvModal: () => void
}) => {
    if (!rdv) return null;
    return createPortal(
        <RdvHistoryModal rdv={rdv} onClosed={closeRdvModal} />,
        document.querySelector("#rdv-history-modal-root")
    );
}
/**
 * The provider to use the specialized modal to display the history of a rendez-vous
 */
const RdvHistoryModalProvider = ({ children }) => {
    let { rdv, setRdv, closeRdvModal } = useRdvHistoryModal();
    return (
        <RdvHistoryModalContext.Provider value={{ setRdv, closeRdvModal }}>
            {children}
            <RdvHistoryModalPortal rdv={rdv} closeRdvModal={closeRdvModal} />
        </RdvHistoryModalContext.Provider>
    );
};

/**
 * The custom hook to use the specialized modal to display the history of a rendez-vous
 */
const useRdvHistoryModalContext = (): {
    setRdv: (r: Rendezvous) => void,
    closeRdvModal: () => void
} => useContext(RdvHistoryModalContext);

export { RdvHistoryModal, RdvHistoryModalProvider, useRdvHistoryModalContext };