import React, {useEffect, useState} from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import Button from "reactstrap/es/Button";

const InfoModal = ({msg, title, level, html, onClosed}: {
    msg?: React.ReactNode,
    title: string,
    level: "success" | "warning" | "info" | "danger",
    html?: boolean,
    onClosed?: () => void
}) => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(o => !o);
    useEffect(() => {
        setOpen(!!msg);
    }, [msg]);

    return (
        <Modal isOpen={open} toggle={toggle} centered={true} zIndex={21000} onClosed={onClosed}>
            <ModalHeader toggle={toggle} charCode={"X"} className={`py-2 ${level ? ("bg-" + level) : ""}`}>
                <span className="text-uppercase font-weight-bolder">{title}</span>
            </ModalHeader>
            <ModalBody className="py-2">
                {msg}
            </ModalBody>
            <ModalFooter className="py-2">
                <Button color="primary" onClick={toggle}>OK</Button>
            </ModalFooter>
        </Modal>
    )
};
export default InfoModal;