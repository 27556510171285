import AsyncLoaderWrapper from "./AsyncLoaderWrapper";
import UController from "components/Controller/UController";
import Sector from "components/ClassWrapper/Sector";
import { addAllToMap, getFullNameWithPrefixDoctor, getLastNames, getLocalDateStrFromISO, printIsoDate, printIsoTime, reduceDict, type Dict } from "Utils";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CompleteRdv, { CR_SORT_BY_DATE_TIME_SESSION } from "components/ClassWrapper/CompleteRdv";
import moment from "moment";
import type { DateString } from "components/ClassWrapper/TimeClasses";
import MaterialTable from "material-table";
import { BED, CHAIR } from "components/ClassWrapper/SeatType";
import { HistoryOutlined, Print } from "@material-ui/icons";
import { generatePDFConvocation, generatePlanningRdvLists } from "tools/pdf_generators";
import Staff from "components/ClassWrapper/Staff";
import type { PlanningTableHeader } from "tools/pdf_generators";
import type { SeatGroup } from "components/ClassWrapper/SeatGroup";
import DateRangeSelector from "components/picker/DateRangeSelector";
import type { LocalDateInterval } from "components/ClassWrapper/TimeClasses";
import Material from "components/ClassWrapper/Material";
import { useRdvHistoryModalContext } from "components/RdvHistoryModal";
import React from "react";

type SyncJobListerProps = {
    sectorDict: Dict<Sector>,
    seatWingDict: Dict<SeatGroup>,
    seatDict: Dict<Material>,
}

const sortStringLocale = (s1: String, s2: String): Number => s1.localeCompare(s2);

const DOCTOR_PLACEHOLDER: Staff = {
    id: -1
}

/**
 * @param {?String} filter to be splitted by space, each component will be matched against <tt>targets</tt>. Returns <tt>true</tt> if at least one component matches
 */
const splitAndMatch = (filter?: String, targets: (component: String) => boolean): boolean => !filter || filter.trim().length === 0 || filter.split(/\s+/).some(targets);
const splitAndMatchAny = (filter?: String, target?: String): boolean => !!target && splitAndMatch(filter, f => target.toUpperCase().includes(f.toUpperCase()));

const SyncJobLister = ({
    sectorDict,
    seatWingDict,
    seatDict
}: SyncJobListerProps) => {
    const [currentHorizon, setCurrentHorizon]: [LocalDateInterval, (ldi: LocalDateInterval) => void] = useState({ start: null, end: null });
    const [completeRdvList, setCompleteRdvList]: [CompleteRdv[], (crdvs: CompleteRdv[]) => void] = useState([]);
    const tableRef = useRef();

    useEffect(() => {
        if (!currentHorizon || !currentHorizon.start || !currentHorizon.end) return;
        UController.appointment.getAllInPeriod(currentHorizon.start, currentHorizon.end)
            .then(crdvList => {
                // Filter out canceled rdv
                crdvList = crdvList.filter(crdv => !crdv.rdv.realCancel)
                // To circumvent the limit of MaterialTable when handling undefined doctor of rdv
                // This also helps filtering more straightforward
                crdvList.forEach(crdv => {
                    if (!crdv.rdv.doctor)
                        crdv.rdv.doctor = DOCTOR_PLACEHOLDER;
                })
                setCompleteRdvList(crdvList);
            });
    }, [currentHorizon]);

    const doctorFullNameMap: Dict<string> = useMemo(() => completeRdvList.filter(c => c.rdv.doctor?.id > 0).map(c => c.rdv.doctor).reduce((m, d) => ({ ...m, [d.id]: getFullNameWithPrefixDoctor(d) }), { [-1]: "A affecter" })
        , [completeRdvList]);

    const seatIdToWingIdMap: { [key: number]: number[] } = useMemo(() => {
        const map: { [key: number]: number[] } = {}
        Object.values(seatWingDict)
            .forEach(wing => wing.seats?.forEach(s => {
                if (map[s.id]) map[s.id].push(wing.id + "");
                else map[s.id] = [wing.id + ""];
            }));
        return map;
    }, [seatWingDict]);

    const seatWingNameMap: Dict<string> = useMemo(() => reduceDict(seatWingDict, "name"), [seatWingDict]);

    const seatNameMap: Dict<string> = useMemo(() => reduceDict(seatDict, "number"), [seatDict]);

    const horizonMoment = useMemo(() => ({
        from: currentHorizon.start ? moment(currentHorizon.start) : moment(),
        to: currentHorizon.end ? moment(currentHorizon.end) : moment()
    }), [currentHorizon.start, currentHorizon.end]);
    const onSelectDateRange = useCallback(({ from, to }) => setCurrentHorizon(() => ({
        start: from.toISOString(true).substring(0, 10),
        end: to.toISOString(true).substring(0, 10)
    })), [setCurrentHorizon]);

    const { setRdv } = useRdvHistoryModalContext();

    return (
        <Row className="m-3" >
            <Col>
                <Card outline={true} color="primary">
                    <CardHeader className="d-flex align-items-baseline">
                        <h2 className="text-white mr-auto">
                            Liste des convocations
                        </h2>
                        <div style={{ marginTop: 5 }}>
                            <DateRangeSelector
                                reloadAuto
                                horizon={horizonMoment}
                                popupPosition="right"
                                definedPeriodsProposals="long"
                                intervalFormat="literal"
                                handleSubmit={onSelectDateRange} />
                        </div>
                    </CardHeader>
                    <CardBody className="d-flex flex-column justify-content-start">
                        <span className="font-italic font-weight-lighter">MAJ à {new Date().toLocaleString()}</span>
                        <MaterialTable
                            data={completeRdvList}
                            tableRef={tableRef}
                            columns={[
                                {
                                    field: "rdv.sessionDay",
                                    title: "Date",
                                    render: (rowData: CompleteRdv | DateString) => printIsoDate(typeof rowData === "string" ? rowData : rowData.rdv.sessionDay),
                                    defaultSort: "asc",
                                    customFilterAndSearch: (filter: string, rowData: CompleteRdv) => printIsoDate(rowData.rdv.sessionDay).includes(filter),
                                    customSort: CR_SORT_BY_DATE_TIME_SESSION,
                                },
                                {
                                    field: "rdv.place",
                                    title: "Aile",
                                    render: (rowData: CompleteRdv): React.ReactNode => <span style={{ display: "block", maxWidth: "80px", textWrap: "wrap" }}>{!seatIdToWingIdMap[rowData.rdv.place.id] ? "" : seatIdToWingIdMap[rowData.rdv.place.id].map(wingId => seatWingNameMap[wingId]).join(", ")}</span>,
                                    grouping: false,
                                    lookup: seatWingNameMap,
                                    customFilterAndSearch: (filter: string[], rowData) => !filter || filter.length === 0 || filter.some(wingId => seatIdToWingIdMap[rowData.rdv.place.id]?.includes(wingId))
                                },
                                {
                                    field: "rdv.place",
                                    title: "Hébergement",
                                    render: (rowData: CompleteRdv): React.ReactNode => printSeat(rowData.rdv.place),
                                    grouping: false,
                                    customFilterAndSearch: (filter: string[], rowData) => !filter || filter.length === 0 || filter.some(seatId => rowData.rdv.place.id?.toString() === seatId),
                                    lookup: seatNameMap,
                                    customSort: (c1, c2) => sortStringLocale(c1.rdv.place.number, c2.rdv.place.number),
                                    filterCellStyle: {
                                        width: "10vw",
                                        position: "absolute"
                                    },
                                    defaultFilter: Object.keys(seatNameMap),
                                },
                                {
                                    field: "rdv.session.start",
                                    title: "Heure de convocation",
                                    render: (rowData: CompleteRdv) => printIsoTime(rowData.rdv.session.start),
                                    filtering: false,
                                    grouping: false,
                                    customSort: CR_SORT_BY_DATE_TIME_SESSION,
                                },
                                {
                                    field: "patient.externalId.value",
                                    title: "IPP",
                                    grouping: false,
                                },
                                {
                                    field: "patient.lastNames",
                                    title: "Nom(s)",
                                    render: (rowData: CompleteRdv) => getLastNames(rowData.patient),
                                    grouping: false,
                                    customFilterAndSearch: (filter: String, rowData) => splitAndMatchAny(filter, rowData.patient.partnerName) || splitAndMatchAny(filter, rowData.patient.partnerName),
                                },
                                {
                                    field: "patient.firstName",
                                    title: "Prénom(s)",
                                    grouping: false,
                                    customFilterAndSearch: (filter: String, rowData) => splitAndMatchAny(filter, rowData.patient.firstName),
                                },
                                {
                                    field: "patient.birthday",
                                    title: "Date de naissance",
                                    render: (rowData: CompleteRdv) => getLocalDateStrFromISO(rowData.patient.birthday),
                                    grouping: false,
                                    filtering: false,
                                },
                                {
                                    field: "protocol.name",
                                    title: "Protocole",
                                    grouping: true,
                                    customFilterAndSearch: (filter: String, rowData) => splitAndMatchAny(filter, rowData.protocol.name),
                                },
                                {
                                    field: "rdv.doctor.id",
                                    title: "Médecin",
                                    defaultGroupOrder: 0,
                                    defaultGroupSort: "desc",
                                    grouping: true,
                                    lookup: doctorFullNameMap,
                                },
                                {
                                    field: "patient.comment",
                                    title: "Note du patient",
                                    grouping: false,
                                    customFilterAndSearch: (filter: String, rowData) => splitAndMatchAny(filter, rowData.patient.comment),
                                },
                                {
                                    field: "rdv.comment",
                                    title: "Commentaire du RDV",
                                    grouping: false,
                                    customFilterAndSearch: (filter: String, rowData) => splitAndMatchAny(filter, rowData.rdv.comment),
                                },
                            ]}
                            options={{
                                showTitle: false,
                                search: false,
                                paging: false,
                                grouping: true,
                                thirdSortClick: true,
                                filtering: true,
                                debounceInterval: 500,
                            }}
                            actions={[
                                {
                                    icon: () => <Print />,
                                    tooltip: "Planning",
                                    isFreeAction: true,
                                    onClick: () => {
                                        if (!tableRef.current) return;
                                        generatePlanningRdvLists(
                                            { startDate: currentHorizon.start, endDate: currentHorizon.end },
                                            getAllCompleteRdvList(tableRef.current),
                                            JOB_LISTER_PRINT_HEADERS
                                        );
                                    }
                                },
                                {
                                    icon: () => <Print />,
                                    onClick: (e, rowData: CompleteRdv) => generatePDFConvocation({
                                        rdv: rowData.rdv,
                                        patient: rowData.patient
                                    }),
                                    tooltip: "Convocation"
                                },
                                (rowData) => ({
                                    icon: () => <HistoryOutlined/>,
                                    isFreeAction: true,
                                    onClick: () => setRdv(rowData.rdv),
                                    tooltip: "Historique"
                                })
                            ]}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: "Aucun rendez-vous trouvé !",
                                },
                                grouping: {
                                    placeholder: "Regrouper par la date, médecin, ou protocole ",
                                    groupedBy: "Regroupé par : "
                                }
                            }}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const JobLister = () => {
    return (
        <AsyncLoaderWrapper loader={() => Promise.all([
            UController.sector.getDict(),
            UController.seatGroup.get({
                page: 0,
                size: 1000,
                search: "type==WING;enabled==true"
            }).then(res => res.array)
                .then(addAllToMap),
            UController.material.get({
                page: 0,
                size: 1000
            }).then(res => res.array)
                .then(addAllToMap)
        ]).then(([sectorDict, seatWingDict, seatDict]) => ({ sectorDict, seatWingDict, seatDict }))}
            onLoadingMessage={"En cours de chargement..."}
        >
            <SyncJobLister />
        </AsyncLoaderWrapper>
    );
}

export default JobLister;

const printSeat = (seat: Material) => <span><i className={`text-center fas ${seat.type.seatType === BED ? "fa-bed"
    : seat.type.seatType === CHAIR ? "fa-chair"
        : "fa-question-circle"
    }`} /> {seat.number}</span>;

const getAllCompleteRdvList = (tableRefCurrent?): CompleteRdv[][] => {
    if (!tableRefCurrent?.dataManager) return [];
    if (!tableRefCurrent.dataManager.grouped || tableRefCurrent.dataManager.groupedData?.length === 0)
        return [tableRefCurrent.dataManager.sortedData];
    let currentGroupedData = tableRefCurrent.dataManager.groupedData;
    while (currentGroupedData[0].groups?.length > 0) {
        currentGroupedData = currentGroupedData.flatMap(g => g.groups);
    }
    return currentGroupedData.map(g => g.data);
}

const JOB_LISTER_PRINT_HEADERS: PlanningTableHeader[] = [
    {
        dataKey: "place",
        header: "Hbgt",
        dataAccessor: crdv => crdv.rdv.place.number
    },
    {
        dataKey: "date",
        header: "Date",
        dataAccessor: crdv => printIsoDate(crdv.rdv.sessionDay)
    },
    {
        dataKey: "start",
        header: "Début",
        dataAccessor: crdv => printIsoTime(crdv.rdv.session.start)
    },
    {
        dataKey: "ipp",
        header: "IPP",
        dataAccessor: crdv => crdv.patient.externalId?.value
    },
    {
        dataKey: "patientLastNames",
        header: "Nom(s)",
        dataAccessor: crdv => getLastNames(crdv.patient)
    },
    {
        dataKey: "patientFirstNames",
        header: "Prénom(s)",
        dataAccessor: crdv => crdv.patient.firstName
    },
    {
        dataKey: "birthday",
        header: "Date de naissance",
        dataAccessor: crdv => crdv.patient.birthday ? getLocalDateStrFromISO(crdv.patient.birthday) : ""
    },
    {
        dataKey: "protocol",
        header: "Protocole",
        dataAccessor: crdv => crdv.protocol.name
    },
    {
        dataKey: "patientComment",
        header: "Note",
        dataAccessor: crdv => crdv.patient.comment,
        style: {
            cellWidth: 20
        }
    },
    {
        dataKey: "doctor",
        header: "Médecin",
        dataAccessor: crdv => crdv.rdv.doctor?.id > 0 ? getFullNameWithPrefixDoctor(crdv.rdv.doctor) : "-"
    },
    {
        dataKey: "rdvComment",
        header: "Commentaire du RDV",
        dataAccessor: crdv => crdv.rdv.comment,
        style: {
            cellWidth: 50
        }
    },
];