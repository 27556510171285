import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { getFullNameWithPrefix, getFullNameWithPrefixDoctor, getISODateFromISODateTime, getLastNamesWithPrefix, printIsoDate } from "Utils";
import { CYCLE_STATUS, CYCLE_STATUS_FR } from "components/ClassWrapper/Cycle";
import { getStatus } from "components/ClassWrapper/Rendezvous";
import { PictureAsPdf, EditOutlined, HistoryOutlined } from '@material-ui/icons';
import { generatePDFConvocation } from "tools/pdf_generators";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import { RdvCancelLabel } from "components/RdvCancelLabel";
import { useRdvHistoryModalContext } from "components/RdvHistoryModal";

const VisualRdv = (props) => {
    let { item, itemContext, getItemProps, minLabelVisibleWidth, onEdit } = props;
    const { setRdv } = useRdvHistoryModalContext();
    minLabelVisibleWidth = minLabelVisibleWidth || 50;
    let rawData: CompleteRdv = item.rawData;
    let _background = itemContext.selected ? "orange" : item.backgroundColor;
    if (rawData.registerStatus === CYCLE_STATUS.TO_DELETE) {
        let stripeColor = itemContext.selected ? "orange" : item.backgroundColor;
        _background = "repeating-linear-gradient(45deg, black, black 4px, " + stripeColor + " 4px, " + stripeColor + " 12px)";
    }
    if (rawData.rdv.realCancel !== null) {
        _background = "repeating-linear-gradient(transparent, #7b817e 7px), repeating-linear-gradient(0.25turn, transparent, #7b817e 7px)";
    }
    return (
        <div
            {...getItemProps({
                style: {
                    background: _background,
                    color: "whitesmoke",
                    border: null, // Remove default style
                    borderColor: itemContext.selected ? item.backgroundColor : "black",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 2,
                },
                className: "rendezvous " + rawData.registerStatus,
            })}
            id={"rendezvous-" + item.id}
        >
            {
                itemContext.dimensions.width >= minLabelVisibleWidth &&
                <span className={"font-weight-bold p-1 rounded bg-dark text-white rendezvous-label"}>
                    {getLastNamesWithPrefix(rawData.patient)}
                </span>
            }
            {
                !itemContext.dragging &&
                <UncontrolledTooltip placement="left" target={"rendezvous-" + item.id} trigger="hover" autohide={false}
                    style={{ maxWidth: "250px", textAlign: "left" }}>
                    <h2 className={!rawData.patient.externalId?.value ? "text-white text-uppercase border-bottom border-white" : "text-white text-uppercase"} >{getFullNameWithPrefix(rawData.patient)}</h2>
                    {!!rawData.patient.externalId?.value && <h3 className="text-white border-bottom border-white">({rawData.patient.externalId?.value})</h3>}
                    {rawData.patient.physicalInfo?.deceased && <h3 className="text-white text-italic">Décédé(e) {rawData.patient.physicalInfo?.deceasedAt && `à ${printIsoDate(getISODateFromISODateTime(rawData.patient.physicalInfo.deceasedAt), false)}`}</h3>}
                    {
                        !rawData.rdv.realCancel &&
                        <div className="d-flex justify-content-between align-items-center">
                            {
                                rawData.rdv.sessionDay >= getISODateFromISODateTime(new Date().toISOString()) &&
                                <span className="text-left" style={{ cursor: "pointer" }} onClick={() => onEdit(rawData.id)} ><EditOutlined />Modifier</span>
                            }
                            {
                                rawData.registerStatus === CYCLE_STATUS.CONFIRMED &&
                                <span className="text-right" style={{ cursor: "pointer" }} onClick={() => generatePDFConvocation(rawData)}>Imprimer <PictureAsPdf /></span>
                            }
                        </div>
                    }
                    {
                        rawData.rdv.registerStatus !== CYCLE_STATUS.TO_CREATE &&
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="text-left" style={{ cursor: "pointer" }} onClick={() => setRdv(rawData.rdv)} >
                                <HistoryOutlined />Historique
                            </span>
                        </div>
                    }
                    <h3 className="text-white text-capitalize font-italic">{getStatus(rawData.rdv).map(s => s.name).join(" | ")}</h3>
                    <div className="border-bottom border-white">
                        <p className="m-0 p-0 font-weight-bold">{rawData.protocol.name}</p>
                        <p className="m-0 p-0">{rawData.rdv.session.start.substr(0, 5)} - {rawData.rdv.session.end.substr(0, 5)}</p>
                        <p className="m-0 p-0">Médecin : {getFullNameWithPrefixDoctor(rawData.rdv.doctor)}</p>
                        <p className="m-0 p-0">Infirmière : {getFullNameWithPrefix(rawData.rdv.nurse)}</p>
                        <p className="m-0 p-0">Note : {rawData.patient.comment || ""}</p>
                        <p className="m-0 p-0">Commentaire : {rawData.rdv.comment || ""}</p>
                        {
                            rawData.rdv.realCancel &&
                            <p className="m-0 p-0">Annulé : <RdvCancelLabel rdv={rawData.rdv} /></p>
                        }
                    </div>
                    <p className="m-0 p-0 font-italic">{CYCLE_STATUS_FR[rawData.registerStatus]}</p>
                </UncontrolledTooltip>
            }
        </div>
    );
}

export default VisualRdv;